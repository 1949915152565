import classNames from 'classnames';
import Link from 'next/link'; // eslint-disable-line no-restricted-imports
import { ReactNode } from 'react';
import { Icon } from '@/components/Icon';
import { trackEvent } from '@/analytics';

interface Props {
  caption: ReactNode;
  href: string;
  className?: string;
  newTab?: boolean;
  analyticsName?: string;
  showIcon?: boolean;
  smallIcon?: boolean;
  onClick?: () => void;
}

// eslint-disable-next-line complexity
export const ExternalLink = ({
  caption,
  className,
  href,
  newTab = true,
  analyticsName,
  showIcon = true,
  smallIcon = false,
  onClick,
}: Props) => {
  const click = (eo: React.MouseEvent<HTMLElement>) => {
    eo.stopPropagation();
    onClick?.();
    if (analyticsName) {
      trackEvent('ProceedToExternalResource', {
        targetUrl: href,
        targetName: analyticsName,
      });
    }
  };

  const iconSize = smallIcon ? '16' : '18';
  const vAlignClass = smallIcon ? 'align-[-2px]' : 'align-[-3px]';
  return (
    <Link
      target={newTab ? '_blank' : ''}
      href={href}
      className={classNames(className, 'text-primary font-medium')}
      onClick={click}
    >
      <span className="leading-4">{caption}</span>
      {showIcon && (
        <Icon
          width={iconSize}
          height={iconSize}
          name="open-filled"
          className={'ml-[5px] ' + vAlignClass}
        />
      )}
    </Link>
  );
};
