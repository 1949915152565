import { ComponentProps, FC } from 'react';
import classNames from 'classnames';

interface Props extends ComponentProps<'button'> {
  secondary?: boolean;
}

const variant = {
  DEFAULT:
    'min-w-48 text-white bg-primary hover:border-primary-800 hover:bg-primary-800 disabled:text-mediumGrey-600 disabled:bg-lightGrey-600 disabled:border-transparent',
  SECONDARY:
    'min-w-48 text-primary hover:border-text-primary-800 hover:border-primary-800 hover:text-primary-800 disabled:text-mediumGrey-600 disabled:border-mediumGrey-600',
};

const defaultClass = 'border border-primary leading-none px-8 py-4 font-medium';

export const Button: FC<Props> = ({ children, secondary = false, className, ...props }) => {
  return (
    <button {...props} className={getButtonClassName(secondary, className)}>
      {children}
    </button>
  );
};

export function getButtonClassName(secondary?: boolean, className?: string) {
  const variantClass = secondary ? variant.SECONDARY : variant.DEFAULT;
  return classNames(defaultClass, variantClass, className);
}
