import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-sanity/dist/visual-editing/VisualEditing.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-loading-skeleton/dist/skeleton.css");
;
import(/* webpackMode: "eager", webpackExports: ["TrackPageView"] */ "/vercel/path0/src/analytics/TrackPageView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SWRProvider"] */ "/vercel/path0/src/api/swrProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModalProvider"] */ "/vercel/path0/src/contexts/ModalContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TranslationsProvider"] */ "/vercel/path0/src/i18n/TranslationsProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/logging/datadog.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css");
