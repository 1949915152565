/**
 * App Insights.
 *
 * Repo: https://github.com/microsoft/ApplicationInsights-JS
 */

import type { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import debounce from 'lodash.debounce';
import { AnalyticsEventMap, attachPageUrlToEvent } from './customEvents';
import { attachMetadataToException } from './exceptions';
import { env } from '@/env';

declare global {
  interface Window {
    appInsights: ApplicationInsights;
  }
}

// AppInsights instance is available globally via script loader.
const appInsights = typeof window === 'object' ? window.appInsights : undefined;

initAppInsights();

function initAppInsights() {
  if (!appInsights) return;
  appInsights.addTelemetryInitializer(attachAppVersion);
  appInsights.addTelemetryInitializer(attachPageUrlToEvent);
  appInsights.addTelemetryInitializer(attachMetadataToException);
}

/**
 * Send page view event with debounce.
 * Reason: Algolia widgets perform several updates of URL.
 */
export const trackPageViewDebounce = debounce(() => appInsights?.trackPageView(), 500);

/**
 * Send custom event.
 * Having event name as a first parameter leads to more concise consumer code.
 *
 * E.g.:
 * trackEvent('Search.Query', { query: 'foo' });
 * trackEvent('Search.ClearAll');
 */
export const trackEvent = <T extends keyof AnalyticsEventMap>(
  name: T,
  // if event doesn't have extra props, do not require second arg
  // if event has extra props, require second arg
  ...args: keyof AnalyticsEventMap[T] extends never ? [] : [AnalyticsEventMap[T]]
) => {
  appInsights?.trackEvent({ name }, args[0]);
};

/**
 * See: https://learn.microsoft.com/en-us/azure/azure-monitor/app/api-custom-events-metrics#authenticated-users
 */
export function setUserId(userId: string) {
  appInsights?.setAuthenticatedUserContext(userId);
}

/**
 * Track exception.
 */
export function trackException(error: Error | string) {
  const exception = typeof error === 'string' ? new Error(error) : error;
  appInsights?.trackException({ exception });
}

function attachAppVersion(envelope: ITelemetryItem) {
  if (envelope.tags) {
    // see: https://github.com/microsoft/ApplicationInsights-JS/issues/785
    envelope.tags['ai.application.ver'] = env.build.version;
  }
}
