import type { ITelemetryItem } from '@microsoft/applicationinsights-web';

type ExceptionData = {
  exceptions?: { message: string; stack: string }[];
  properties?: { message?: string; pageUrl?: string };
};

/**
 * Automatically attach pageUrl and message to baseData.properties.
 */
export function attachMetadataToException(envelope: ITelemetryItem) {
  if (!envelope.name.endsWith('.Exception')) return;
  const baseData = envelope.baseData as ExceptionData | undefined;
  if (!baseData) return;
  const firstException = baseData.exceptions?.[0];
  baseData.properties = Object.assign({}, baseData.properties, {
    pageUrl: window.location.href,
    message: firstException?.message || 'Unknown error',
  });
}
