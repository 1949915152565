/**
 * Datadog logging.
 *
 * Repo: https://github.com/DataDog/browser-sdk/tree/HEAD/packages/rum
 */
'use client';

import { env } from '../env';

// Load Datadog asynchronously to reduce initial bundle size.
void loadDatadog();

async function loadDatadog() {
  const { datadogRum } = await import(/* webpackChunkName: "datadog" */ '@datadog/browser-rum');
  const { applicationId, clientToken } = env.datadog;

  datadogRum.init({
    applicationId,
    clientToken,
    site: 'datadoghq.com',
    // service should be 'buyeasy.mytakeda.com' for all envs
    service: 'buyeasy.mytakeda.com',
    env: env.name,
    version: env.build.version,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();

  return datadogRum;
}
