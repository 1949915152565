/**
 * Returns env var with checking for empty value.
 * Correclty handles server-side only vars (without NEXT_PUBLIC_ prefix).
 * Uses fn parameter instead of string name b/c next inlines env variables on client.
 *
 * Usage:
 * getEnvVar(() => process.env.VAR_NAME);
 *
 * Note:
 * special flag ALLOW_EMPTY_ENV_VARS allows to skip env checking (useful for e2e tests).
 */
export function getEnvVar(fn: () => string | undefined): string {
  const value = fn();
  if (!value && !process.env.ALLOW_EMPTY_ENV_VARS) {
    const varName = extractEnvVarNameFromFn(fn);
    const isServerSide = typeof window === 'undefined';
    const isPublicVar = varName.startsWith('NEXT_PUBLIC_');
    // on server side all vars must be defined
    // on client side all public vars must be defined, server-only vars become ''
    if (isServerSide || isPublicVar) {
      throw new Error(`Missing env var "${varName}"`);
    }
  }

  return value || '';
}

function extractEnvVarNameFromFn(fn: () => string | undefined) {
  const fnBody = fn.toString();
  const varName = fnBody.match(/\.env\.([A-Z0-9_]+)/)?.[1];
  if (!varName) throw new Error(`Incorrect env var getter "${fnBody}"`);
  return varName;
}
