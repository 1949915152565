import { FC } from 'react';
import { IconName } from './iconName';

interface Props {
  name: IconName;
  width?: string;
  height?: string;
  className?: string;
}

/* if you have any questions about how to use it, look at the section about icons in the README */

export const Icon: FC<Props> = ({ name, width = 24, height = 24, className = '', ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      style={{ fill: 'currentcolor' }}
      className={`inline ${className}`}
      {...props}
    >
      <use href={`/icons.svg#${name}`} />
    </svg>
  );
};
