'use client';
import { ReactNode } from 'react';
import { SWRConfig } from 'swr';
import { jsonFetcher } from './fetcher';

const defaultFetcher = async (key: string | [string, RequestInit]) => {
  const [url, options] = Array.isArray(key) ? key : [key, {}];
  return jsonFetcher(url, options);
};

export function SWRProvider({ children }: { children: ReactNode }) {
  return (
    <SWRConfig value={{ fetcher: defaultFetcher, shouldRetryOnError: false }}>{children}</SWRConfig>
  );
}
